<script lang="ts" setup>
const auth = useAuth()
</script>

<template>
  <NuxtLoadingIndicator />
  <div
    data-layout="token-designer/tokenomics"
    class="bg-forgd-bgd-200 text-forgd-primary-900 min-h-screen font-normal font-display"
  >
    <div class="flex min-h-screen relative">
      <AppMenuExpandable :expanded="true" class="z-5" />
      <div class="flex flex-col flex-grow">
        <div
          class="h-[80px] px-5 flex justify-between items-center border-b border-forgd-bgd-600"
        >
          <div class="flex gap-4 items-center">
            <img src="/app/illustrations/tokenomics-v2-icon.svg" alt="Tokenomics Icon" width="32" height="32">
            <div class="text-xl font-semibold flex-shrink-0">
              Tokenomics
            </div>
          </div>

          <AppProjectChip />
        </div>

        <div class="flex flex-grow">
          <AppMain>
            <slot />
          </AppMain>
        </div>

        <AppFooter />
      </div>
    </div>
  </div>

  <UNotifications />
</template>
